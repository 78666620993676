table {
  border-collapse: collapse;
  width: 100%;
}

*:focus {
  outline: none;
}

h4 {
  word-wrap: break-word;
}

body {
  font-family: 'Noto Sans JP', sans-serif;

}

TextField {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 100;
}

html {
  scroll-behavior: smooth;
  font-weight: 100;
  /* background-image: url(./assets/img/cookieBackground.jpg); */
  background-color: white;
  background-size: cover;
  background-size: 500%;
  height: 130%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: scroll;
  overflow-x: hidden;
  /* touch-action: none; */
}


::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

.cardAnimation-appear {
  opacity: 0.01;
  transform: rotate(20deg);
}

.cardAnimation-appear.cardAnimation-appear-active {
  opacity: 1;
  transition: all 1s ease-in;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

input[type=search] {
  -webkit-appearance: none;
}

.search-container {
  display: flex;
  justify-content: flex-end;

  .wrap-input {
    position: relative;
  
    input {
      padding: 6px 10px 6px 35px;
      border-radius: 4px;
      width: 270px;
      border: 1px solid #9C9C9C;
    }
  
    span {
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }
}

